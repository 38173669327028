<template>
  <div class="page" id="reorganize">
    <el-form
        @keyup.enter.native="getDataList(1,1)"
        class="query-form" :inline="true" size="small" ref="inputForm" :model="inputForm" label-width="100px">
      <el-form-item label="所属类型" prop="thematicType">
        <el-select v-model="inputForm.thematicType"
                   placeholder="请选择所属类型"
                   style="width: 100%"
                   clearable>
          <el-option
              v-for="item in this.$dictUtils.getDictList('thematic_type')"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建人" prop="createUser">
        <el-select
            style="width: 100%;"
            v-model="inputForm.createUser"
            filterable
            remote
            clearable
            reserve-keyword
            placeholder="请选择创建人"
            :remote-method="remoteMethod"
            :loading="personnelLoading">
          <el-option
              v-for="item in personnelList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="专题库名称" prop="thematicName">
        <el-input v-model.trim="inputForm.thematicName" clearable maxlength="20"
                  placeholder="请输入专题库名称(限20字)"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="getDataList(1,1)" size="small">查询</el-button>
        <el-button icon="el-icon-refresh-right" @click="resetting()" size="small">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="bg-white">
      <div class="text_right">
        <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
      </div>
      <el-table
          :data="dataList"
          size="small"
          :row-key="'id'"
          @selection-change="selectionChangeHandle"
          v-loading="loading"
          ref="multipleTable"
          height="calc(100vh - 330px)"
          class="table"
      >
        <el-table-column v-for="item in tableTitleList" :key="item.id" :prop="item.fieldEname" :label="item.fieldName"
                         :min-width="(item.fieldName == '专题库所属类型' || item.fieldName == '专题库建设描述') ? 110 : 0"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="item.fieldName == '专题库所属类型'">{{ $dictUtils.getDictLabel("thematic_type", scope.row[item.fieldEname], '-') }}</div>
            <div v-else-if="item.fieldName == '专题库归属'">{{ scope.row[item.fieldEname] == 0 ? '全馆' : scope.row[item.fieldEname] == 1 ? '部门选择' : '个人选择' }}</div>
            <div v-else>{{ scope.row[item.fieldEname] }}</div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="contrast(scope.row,1)"
                       v-show="hasPermissionButton(`dyyg:manage:publicLibrary:contrast`)">专题库对比
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       @click="resource(scope.row)"
                       v-show="hasPermissionButton(`dyyg:manage:publicLibrary:resource`)">资源管理
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       v-if="scope.row.collectionState == 0"
                       @click="favorites(scope.row)"
                       v-show="hasPermissionButton(`dyyg:manage:publicLibrary:favorites`)">加入收藏
            </el-button>
            <el-button style="margin-bottom: 10px" size="mini" type="text"
                       v-else
                       @click="cancelFavorites(scope.row)"
                       v-show="hasPermissionButton(`dyyg:manage:publicLibrary:favorites`)">取消收藏
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text_center">
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="current"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="size"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <contrast ref="contrast"></contrast>
    <coll-list ref="collList" @refresh="getDataList()"></coll-list>
    <!--列表设置拖拽排序弹窗-->
    <DraggablePop :fieldType="fieldType" :moduleId="id" :setShow="setShow" @getTbList="getTbList"></DraggablePop>
  </div>
</template>

<script>
import Contrast from "@/views/modules/dyyg/libraryManagement/contrast.vue";
import CollList from "@/views/modules/dyyg/libraryManagement/collList.vue";
import DraggablePop from "@/components/draggable/draggablePop2.vue";

export default {
  name: "publicLibrary",
  components: {DraggablePop, CollList, Contrast},
  data() {
    return {
      inputForm: {
        thematicType: '',
        thematicName: '',
        createUser: '',
      },

      personnelLoading: false,
      personnelList: [],

      loading: false,
      dataList: [],
      dataListSelect: [],
      current: 1,
      size: 10,
      total: 0,

      id: '',
      setShow: false, // 是否显示列表设置拖拽排序弹窗
      tableTitleList: [],
      fieldType:1,
    }
  },
  mounted() {
    let homeSetOpt = JSON.parse(sessionStorage.getItem('homeSetOpt'))
    if (homeSetOpt) {
      this.$refs.collList.init(1, '', '', homeSetOpt)
      sessionStorage.removeItem('homeSetOpt')
    }
    this.getDataList()
    this.remoteMethod('')
  },

  methods: {
    getDataList(type, dividePage) {
      if (type == 1) {
        this.current = 1
      }
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
      }
      this.loading = true
      this.$axios(this.api.dyyg.thematiclibraryCommonList, {
        current: this.current,
        size: this.size,
        ...this.inputForm,
      }, 'get').then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
          })
          if (this.dataList.length == 0 && this.current > 1) {
            this.current--
            this.getDataList()
          }
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    // 设置
    setTb() {
      this.setShow = true;
    },

    // 接收子组件（排序弹窗）传过来的值
    getTbList(data, state) {
      this.tableTitleList = data
      this.setShow = state;
    },

    //重置
    resetting() {
      this.$refs.inputForm.resetFields();
      this.getDataList(1, 1)
    },

    //边打边搜
    remoteMethod(query) {
      this.personnelLoading = true;
      this.$axios(this.api.collection.searchUserByLike, {username: query}, 'get').then(data => {
        if (data.status) {
          this.personnelList = data.data
          this.personnelLoading = false;
        }
      })
    },

    //专题库对比
    contrast(row, state) {
      if (row.resourceNum == 0) {
        this.$message.warning('资源数量为0')
        return
      }
      this.$refs.contrast.init(row, state)
    },

    //资源管理
    resource(row) {
      let searchData = {
        current: this.current,
        size: this.size,
        ...this.inputForm,
      }
      this.$refs.collList.init(0, row, searchData)
    },

    //收藏
    favorites(row) {
      this.$confirm(`确定加入我的收藏？加入后可在我的收藏界面进行查看`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let userId = JSON.parse(sessionStorage.getItem('userInfo'))
        this.$axios(this.api.dyyg.thematiclibraryCollection, {
          collectionType: '1',
          foreignKeyId: row.id,
          userId: userId.id,
          foreignType: 0,
        }, 'post').then((res) => {
          if (res.status) {
            this.$message.success('收藏成功')
            this.getDataList('', 1)
          } else {
            this.$message.error(res.msg);
          }
        })
      })
    },

    //取消收藏
    cancelFavorites(row) {
      this.$confirm(`确定取消收藏？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.dyyg.thematiclibraryRemoveCollection, {ids: [row.id]}, 'delete').then((res) => {
          if (res.status) {
            this.$message.success('取消收藏成功')
            this.getDataList('', 1)
          } else {
            this.$message.error(res.msg);
          }
        })
      })
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList('', '');
    },

  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}
</style>
